import { graphql, useStaticQuery } from 'gatsby'
import formatNodes from '../utils/formatNodes'

const useAddressPage = () => {
  const nodes = useStaticQuery(
    graphql`
      fragment FluidImage on File {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      query {
        officeHeaderLeft: file(relativePath: { eq: "office/header-left.jpg" }) {
          ...FluidImage
        }
        officeHeaderRight: file(
          relativePath: { eq: "office/header-right.jpg" }
        ) {
          ...FluidImage
        }
        officeGalleryLeft1: file(
          relativePath: { eq: "office/gallery-left-1.jpg" }
        ) {
          ...FluidImage
        }
        officeGalleryLeft2: file(
          relativePath: { eq: "office/gallery-left-2.jpg" }
        ) {
          ...FluidImage
        }
        officeGalleryLeft3: file(
          relativePath: { eq: "office/gallery-left-3.jpg" }
        ) {
          ...FluidImage
        }
        officeGalleryLeft4: file(
          relativePath: { eq: "office/gallery-left-4.jpg" }
        ) {
          ...FluidImage
        }
        officeGalleryLeft5: file(
          relativePath: { eq: "office/gallery-left-5.jpg" }
        ) {
          ...FluidImage
        }
        officeGalleryRight1: file(
          relativePath: { eq: "office/gallery-right-1.jpg" }
        ) {
          ...FluidImage
        }
        officeGalleryRight2: file(
          relativePath: { eq: "office/gallery-right-2.jpg" }
        ) {
          ...FluidImage
        }
        officeGalleryRight3: file(
          relativePath: { eq: "office/gallery-right-3.jpg" }
        ) {
          ...FluidImage
        }
        officeGalleryRight4: file(
          relativePath: { eq: "office/gallery-right-4.jpg" }
        ) {
          ...FluidImage
        }
        officeGalleryRight5: file(
          relativePath: { eq: "office/gallery-right-5.jpg" }
        ) {
          ...FluidImage
        }
      }
    `
  )
  return formatNodes(nodes)
}

export default useAddressPage
